<template>
  <div class="app common-content">
    <Banner :BannerImg="BannerImg"></Banner>
    <!--<template v-for="(item,index) in detail">-->
      <!--<Carousel :item="item" :key="index"></Carousel>-->
    <!--</template>-->
    <template v-for="(item,index) in detail">
      <TempCarousel :item="item" :key="index"></TempCarousel>
    </template>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import BannerImg from '../assets/images/tax-swiper.jpg'
// import Carousel from '../components/Carousel.vue'
import TempCarousel from '../components/TempCarousel.vue'
export default {
  components: {
    Banner,
    // Carousel,
    TempCarousel
  },
  data () {
    return {
      BannerImg: BannerImg,
      detail: []
    }
  },
  created: function () {},
  methods: {
    getSolutions () {
      this.$store.dispatch('getSolutions').then(res => {
        this.detail = res
      })
    }
  },
  mounted () {
    this.getSolutions()
  }
}
</script>

<style lang="less">

</style>
