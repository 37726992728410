<template>
  <div class="clearfix">
    <div class="carousel">
      <div class="common-title">
        <p>{{item.tag_name}}</p>
        <span></span>
      </div>
      <div class="carousel-bg" v-if="item.solutions.length>0">
        <div class="common-carousel">
          <!-- 箭头 -->
          <span class="arrow-left arrow" @click="previousPage">
            <svg class="icon myIconStyle" aria-hidden="true">
              <use xlink:href="#icon-jiantou"></use>
            </svg>
          </span>
          <span class="arrow-right arrow" @click="nextPage">
            <svg class="icon myIconStyle" aria-hidden="true">
              <use xlink:href="#icon-jiantou"></use>
            </svg>
          </span>
          <div class="common-content clearfix">
            <ul :style="'left: ' + left + 'px;width: ' + 320 * item.solutions.length + 'px'">
              <li v-for="(ele, index) in item.solutions" :key="index" :style="'left: ' + index * 320 + 'px;'">
                <div class="carousel-content-item-img">
                  <img :src="ele.cover" alt>
                </div>
                <div>
                  <h4>{{ele.title}}</h4>
                  <p>{{ele.digest}}</p>
                  <router-link active-class="" :to="{name: 'Solutiondetail', params: {id: ele.id}}" target="_blank">查看详情>></router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  data () {
    return {
      left: 0,
      page: 1,
      lastPage: 1,
      swiperHeight: '',
      clientWidth: 0
    }
  },
  methods: {
    nextPage () {
      if (Math.abs(this.left) + 320 < 320 * this.item.solutions.length) {
        this.left = this.left - 320
      } else {
        if (this.lastPage > this.page) {
        }
      }
    },
    // 上一页
    previousPage () {
      if (this.left < 0) {
        this.left = this.left + 320
      }
    }
  },
  mounted () {
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    window.addEventListener('resize', () => {
      if (document.querySelectorAll('.swiper-item-img')[0]) {
        this.swiperHeight = document.querySelectorAll('.swiper-item-img')[0].height.toString()
      }
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    })
  }
}
</script>

<style lang="less">
  .carousel {
    .icon {
      width: 70px;
      height: 80px;
    }
    .carousel-bg {
      height: 600px;
      .common-carousel {
        position: relative;
        .arrow {
          position: absolute;
          top: 45%;
          z-index: 99;
        }
        .arrow-left {
          transform: rotate(180deg);
          left: 4rem;
        }
        .arrow-right {
          right: 4rem;
        }
        .common-content {
          width: 960px;
          height: 500px;
          margin: 20px auto 0;
          overflow: hidden;
          position: relative;
          padding-top: 50px;
          ul {
            display: flex;
            height: 100%;
            position: absolute;
            transition: left 1.1s linear;
            li {
              width: 270px;
              margin: 0 25px;
              height: 450px;
              border-radius: 10px;
              box-shadow: 2px 5px 20px @global-gray;
              background: @global-white;
              position: absolute;
              top: 0;
              .carousel-content-item-img {
                height: 220px;
                width: 100%;
                margin-bottom: 15px;
                border-radius:  @border-radius;
                img {
                  display: inline-block;
                  height: 100%;
                  width: 100%;
                  border-radius: @border-radius;
                }
              }
              h4 {
                letter-spacing: 1px;
                text-align: center;
                margin-bottom: 15px;
                padding: 0 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              p {
                text-indent: 2em;
                margin: 0 20px 35px 20px;
                line-height: 25px;
                letter-spacing: 1px;
                // font-size: 15px;
                color: #979a9c;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                overflow: hidden;
                -webkit-box-orient: vertical;
              }
              a {
                position: absolute;
                left: 50%;
                bottom: 25px;
                margin-left: -60px;
                display: block;
                width: 110px;
                height: 38px;
                line-height: 38px;
                border-radius: 8px;
                /*background: #efcc2b;*/
                // font-size: 15px;
                letter-spacing: 1px;
                text-align: center;

              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1160px) and (min-width: 993px) {
    .carousel {
      .carousel-bg {
        .common-carousel {
          .arrow-left {
            left: 8.5rem;
          }
          .arrow-right {
            right: 8.5rem;
          }
        }
      }
      .common-content {
        width: 640px !important;
      }
    }
  }
  @media screen and (max-width: 992px) and (min-width: 700px) {
    .carousel {
      .carousel-bg {
        .common-carousel {
          .arrow-left {
            left: 1rem;
          }
          .arrow-right {
            right: 1rem;
          }
        }
      }
      .common-content {
        width: 640px !important;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .carousel {
      .carousel-bg {
        .common-carousel {
          .arrow-left {
            left: 0;
          }
          .arrow-right {
            right: 0;
          }
        }
      }
      .common-content {
        width: 320px !important;
        .icon {
          width: 60px;
          height: 80px;
        }
      }
    }
  }
</style>
