<template>
  <div>
    <img id="banner" class="banner" :src="BannerImg" />
  </div>
</template>

<script>
import ErrorUrl from '../assets/images/tax-swiper.jpg'
export default {
  props: {
    BannerImg: String
  },
  data () {
    return {}
  },
  mounted: function () {
    this.$nextTick(function () {
      let isImgError = document.getElementById('banner')
      isImgError.onerror = function () {
        this.src = ErrorUrl
      }
    })
  }
}
</script>
<style lang="less">
.banner {
  width: 100%;
}
</style>
